@font-face {
    font-family: "Rota-Bold";
    src: local("Rota"), url(./assets/fonts/Rota-Bold.otf) format("truetype");
}

@font-face {
    font-family: "Rota-Italic";
    src: local("Rota"), url(./assets/fonts/Rota-Italic.otf) format("truetype");
}

@font-face {
    font-family: "Rota-Regular";
    src: local("Rota"), url(./assets/fonts/Rota-Regular.otf) format("truetype");
}

body {
    font-family: "Rota-Regular";
}

.row {
    display: flex;
    flex-direction: row;
}

.column {
    display: flex;
    flex-direction: column;
}
