.dropdown-menu {
    position: relative;

    .title {
        display: flex;
        align-items: center;
        padding: 16px;
        color: #000000;
        cursor: pointer;

        .toggle-icon {
            width: 16px;
            height: 16px;
            margin-left: 10px;
            background: red;
        }
    }

    .items {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        z-index: 1;
        background-color: #ffffff;

        .item {
            padding: 16px;
            cursor: pointer;
        }
    }
}
