#header {
    display: flex;
    justify-content: space-between;
    background-color: #FFFFFF;

    .main-logo {
        width: 80px;
        height: 50px;
        object-fit: contain;

        img {
            width: 100%;
            height: 100%;
        }
    }
}